$( document ).ready(function() {
    window.slimmage.checkResponsiveImages(1);
    $(".scrolldowntop").click(function() {
        $('html,body').animate({scrollTop: $(".frontstal").offset().top - 0},'slow');
    });
    
    $(".scrolldowngreen").click(function() {
        $('html,body').animate({scrollTop: $(".frontindu").offset().top - 0},'slow');
    });
	
	$(".scrolldowngray").click(function() {
        $('html,body').animate({scrollTop: $(".footer").offset().top - 0},'slow');
    });
	
	$(".scrolltop").click(function() {
        $('html,body').animate({scrollTop: $(".topcontent").offset().top - 200},'slow');
    });
	
	$("img").addClass("img-responsive");
	
	if(window.location.href.indexOf("staldinventar") > -1) {
	   $(".introbg").removeClass("introbg").addClass("introimg");
		$(".dropdown").css("display","block");
    }
	if(window.location.href.indexOf("industri") > -1) {
	   $(".introbg").removeClass("introbg").addClass("induimg");
		$(".dropdown").css("display","block");
    }
	
	
	if($(".introimg").length){
		sliderFade($(".introimg").data("bgcount"));
	}
});

function sliderFade(count){
	var item = $(".introimg");
	var images = [];
	for (i = 1; i < count+1; i++) { 
		images.push(item.data("bg"+i));
	}
	
	if(count > 1){
		rotate(images);
	}
	function rotate(images){
    	var i = 1;
    	setInterval(function(){
            $(".introimg").css('background-image', 'url(' + images[i] + ')');
            i++;
            if(i >= images.length) i = 0;
        },8000);
	}
}